import Vue from "vue";
import VueRouter from "vue-router";

import Storage from "@/services/storage.js";

import { FeaturedVideoModuleRoutes } from "@/views/featured_video/services/router.js";
import { DevotionalModuleRoutes } from "@/views/devotional/services/router.js";
import { WorshipModuleRoutes } from "@/views/worship/services/router.js";
import { AppManagementModuleRoutes } from "@/views/app_management/services/router.js";
import { AnnouncementModuleRoutes } from "@/views/announcement/services/router.js";
import { ReportsAndAnalyticsModuleRoutes } from "@/views/reports_and_analytics/services/router.js";
import { LoginModuleRoutes } from "@/views/login/services/router.js";
import { DashboardModuleRoutes } from "@/views/dashboard/services/router.js";
import { ChatSupportModuleRoutes } from "@/views/chat_support/services/router.js";
import { ExperienceModuleRoutes } from "@/views/experience/services/router.js";
import { EventModuleRoutes } from "@/views/event/services/router.js";
import { BadgesModuleRoutes } from "@/views/badges/services/router.js";
import { DonationsModuleRoutes } from "@/views/reports_and_analytics/donations/services/router.js";
import { UsersModuleRoutes } from "@/views/users/services/router.js";
import { PrayersModuleRoutes } from "@/views/prayers/services/router.js";
import { AppActivitiesModuleRoutes } from "@/views/reports_and_analytics/app_activities/services/router.js";
import { GivingVideoModuleRoutes } from "@/views/giving_video/services/router.js";
// import { ClassModuleRoutes } from "@/views/class/services/router";

let storage = new Storage();

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "AppLayout",
    component: () => import("../views/AppLayout.vue"),
    redirect: "/dashboard",
    children: [
      ...DashboardModuleRoutes,
      ...AppManagementModuleRoutes,
      ...AnnouncementModuleRoutes,
      ...ReportsAndAnalyticsModuleRoutes,
      ...FeaturedVideoModuleRoutes,
      ...DevotionalModuleRoutes,
      ...WorshipModuleRoutes,
      ...ChatSupportModuleRoutes,
      ...ExperienceModuleRoutes,
      ...EventModuleRoutes,
      ...BadgesModuleRoutes,
      ...DonationsModuleRoutes,
      ...UsersModuleRoutes,
      ...PrayersModuleRoutes,
      ...AppActivitiesModuleRoutes,
      ...GivingVideoModuleRoutes,
      // ...ClassModuleRoutes,
      {
        path: "/test",
        name: "Test",
        component: () => import("../views/app_management/pages/Test.vue"),
        meta: { requiredAuth: true }
      },
      {
        path: "/drag-drop",
        name: "DragDrop",
        component: () => import("../views/test/DragDropTest.vue"),
        meta: { requiredAuth: true }
      }
    ]
  },
  ...LoginModuleRoutes
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let authenticated = storage.get("authenticated");

  if (to.matched.some(record => record.meta.requiredAuth)) {
    //Redirect if not authenticated
    if (!authenticated) {
      next("/login");

      return;
    }
  }

  next();
});

export default router;
