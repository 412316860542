import Vue from "vue";
import moment from "moment";

import { GLOBAL_SNACKBAR_EVENT_BUS } from "@/components/miscellaneous/snackbars/js/globalSnackbarEventbus";
import { convertUtcToLocal } from "./helpers/date";

Vue.mixin({
  filters: {
    MIXINS_TIMESTAMPS: function(value) {
      return moment(value).format("M-DD-YYYY hh:mm:ss A");
    },
    MIXINS_DATE: function(value) {
      if (value) {
        return moment(value).format("M-DD-YYYY");
      }

      return value;
    },
    MIXINS_FULL_DATE: function(value) {
      if (value) {
        return moment(value).format("MMMM DD, YYYY");
      }

      return value;
    },
    MIXINS_TO_UPPERCASE: function(value) {
      if (value) {
        return value.toUpperCase();
      }

      return value;
    },
    MIXINS_UTC_TO_LOCAL_TIMESTAMPS(value) {
      if (value) {
        return convertUtcToLocal(value).format("M-DD-YYYY hh:mm:ss A");
      }

      return null;
    }
  },
  data: () => ({
    MIXINS_REQUIRED_RULES: [v => !!v || "This field  is required"],
    MIXINS_EMAIL_RULES: [v => /.+@.+\..+/.test(v) || "Must be a valid e-mail"],
    MIXINS_DATE_TODAY_DATE: moment().format("M-DD-YYYY")
  }),
  methods: {
    MIXINS_GO_TO_PAGE(page) {
      this.$router.push(page).catch(() => {});
    },
    MIXINS_SHOW_SNACKBAR(color, text) {
      GLOBAL_SNACKBAR_EVENT_BUS.$emit("showSnackbar", {
        show: true,
        color,
        text
      });
    }
  }
});
