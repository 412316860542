const BASE_PATH = "/experiences";

export const ExperienceModuleRoutes = [
  {
    path: BASE_PATH,
    name: "Experiences",
    component: () => import("@/views/experience/pages/Experience.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  },
  {
    path: `${BASE_PATH}/create`,
    name: "CreateExperiences",
    component: () => import("@/views/experience/pages/CreateExperience.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  },
  {
    path: `${BASE_PATH}/update/:id`,
    name: "UpdateExperiences",
    component: () => import("@/views/experience/pages/CreateExperience.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  },
  {
    path: `${BASE_PATH}/:id`,
    name: "ShowExperience",
    component: () => import("@/views/experience/pages/ShowExperience.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
