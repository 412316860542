const BASE_PATH = "/events";

export const EventModuleRoutes = [
  {
    path: BASE_PATH,
    name: "EventModule",
    component: () => import("@/views/event/EventModule.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    },
    redirect: "/",
    children: [
      {
        path: "/",
        name: "Events",
        component: () => import("@/views/event/pages/Event.vue"),
        meta: {
          requiredAuth: true,
          roles: ["super_admin", "admin", "developer"]
        }
      }
    ]
  }
];
