import Repository from "../repository";

let type = process.env.VUE_APP_API_TYPE;

const dreamTeamChatResource = "dream-team-chats";
const connectGroupChatResource = "connect-group-chats";
const pastorChatResource = "pastor-chats";

export default {
  getDummyContacts(numberOfContact = 5) {
    let contacts = [];
    let message =
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut massa consequat, tempus arcu eget, porta ante. Etiam ultrices eros augue, egestas semper turpis venenatis in. Nullam mollis neque urna, et sodales ligula scelerisque fringilla. Aenean nibh ex, ornare eu ante eget, feugiat fringilla sem. Quisque in felis facilisis, semper.";

    
    for (let index = 0; index <= numberOfContact; index++) {
      let unreadCount = Math.floor((Math.random() * 3));

      let id = index + 1;

      contacts.push({
        id,
        name: `Name ${index}`,
        lastMessage: message,
        unreadCount,
        class: {
          card: unreadCount ? "bg-unread-message" : "main-bg",
          name: unreadCount ? "text-color-white" : "text-color-title",
          message: unreadCount ? "text-color-white" : "text-color-black"
        }
      });
    }

    return contacts;
  },
  getDummyConversations(numberOfCoversation = 5) {
    let conversations = [];
    let message =
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut massa consequat, tempus arcu eget, porta ante. Etiam ultrices eros augue, egestas semper turpis venenatis in. Nullam mollis neque urna, et sodales ligula scelerisque fringilla. Aenean nibh ex, ornare eu ante eget, feugiat fringilla sem. Quisque in felis facilisis, semper.";

    for (let index = 0; index <= numberOfCoversation; index++) {
      let sendByMe = Math.floor((Math.random() * 2));

      conversations.push({
        me: sendByMe ? true : false,
        message: index + message,
        class: sendByMe ? "bg-secondary text-color-white" : "main-bg text-color-black",
        timestamp: "2020-11-23T13:28:28.000000Z"
      });
    }

    return conversations;
  },
  getContactsViaRepository(repositoryKey, payload) {
    /**
     * see ChatGroupCards.vue chatGroups[key].value
     * for the value of repositoryKey
     */

    if (repositoryKey === "dream-team") {
      return Repository.get(`${type}/${dreamTeamChatResource}`, payload);
    }

    if (repositoryKey === "connect-group") {
      return Repository.get(`${type}/${connectGroupChatResource}`, payload);
    }

    if (repositoryKey === "pastor-chat") {
      return Repository.get(`${type}/${pastorChatResource}`, payload);
    }
  },
  getConversationsViaRepository(repositoryKey, id, payload) {
    /**
     * see ChatGroupCards.vue chatGroups[key].value
     * for the value of repositoryKey
     */

    if (repositoryKey === "dream-team") {
      return Repository.get(
        `${type}/${dreamTeamChatResource}/${id}/messages`,
        payload
      );
    }

    if (repositoryKey === "connect-group") {
      return Repository.get(
        `${type}/${connectGroupChatResource}/${id}/messages`,
        payload
      );
    }

    if (repositoryKey === "pastor-chat") {
      return Repository.get(
        `${type}/${pastorChatResource}/${id}/messages`,
        payload
      );
    }
  },
  sendMessageViaRepository(repositoryKey, id, payload) {
    /**
     * see ChatGroupCards.vue chatGroups[key].value
     * for the value of repositoryKey
     */

    if (repositoryKey === "dream-team") {
      return Repository.post(
        `${type}/${dreamTeamChatResource}/${id}/messages`,
        payload
      );
    }

    if (repositoryKey === "connect-group") {
      return Repository.post(
        `${type}/${connectGroupChatResource}/${id}/messages`,
        payload
      );
    }

    if (repositoryKey === "pastor-chat") {
      return Repository.post(
        `${type}/${pastorChatResource}/${id}/messages`, 
        payload
      );
    }
  }
};
