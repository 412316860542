const BASE_PATH = "/chat-supports";

export const ChatSupportModuleRoutes = [
  {
    path: BASE_PATH,
    name: "ChatSupports",
    component: () => import("@/views/chat_support/pages/ChatSupport.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
