const BASE_PATH = "/app-managements";

export const AppManagementModuleRoutes = [
  {
    path: BASE_PATH,
    name: "AppManagements",
    component: () => import("@/views/app_management/pages/People.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
