export const ADD_PUSHER_CHANNEL = "pusher/add-pusher-channel";
export const CLEAR_PUSHER_CHANNEL = "pusher/clear-pusher-channel";

const pusherModule = {
  state: {
    channels: []
  },

  actions: {
    [ADD_PUSHER_CHANNEL]({ state }, channel) {
      state.channels.push(channel);
    },

    [CLEAR_PUSHER_CHANNEL]({ state }) {
      state.channels.splice(0, state.channels.length);
    }
  }
};

export default pusherModule;
