const BASE_PATH = "/badges";

export const BadgesModuleRoutes = [
  {
    path: BASE_PATH,
    name: "Badges",
    component: () => import("@/views/badges/pages/Badges.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];