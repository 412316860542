const BASE_PATH = "/dashboard";

export const DashboardModuleRoutes = [
  {
    path: BASE_PATH,
    name: "Dashboard",
    component: () => import("@/views/dashboard/pages/Dashboard.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
