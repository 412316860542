import moment from "moment";

/** 
 * @param {String} date 
 * @returns moment()
 */
export function convertUtcToLocal(date) {
    let utc = moment.utc(date);

    return moment(utc).local();
}

/**
 * @param {String} date 
 * @returns moment()
 */
export function setToUtc(date) {
    return  moment(date).utc();
}