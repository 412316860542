const BASE_PATH = "/prayers";

export const PrayersModuleRoutes = [
  {
    path: BASE_PATH,
    name: "Prayers",
    component: () => import("@/views/prayers/pages/Prayers.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
