const BASE_PATH = "/reports-and-analytics";

export const ReportsAndAnalyticsModuleRoutes = [
  {
    path: BASE_PATH,
    name: "ReportsAndAnalytics",
    component: () =>
      import("@/views/reports_and_analytics/pages/ReportsAndAnalytics.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
