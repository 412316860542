const BASE_PATH = "/users";

export const UsersModuleRoutes = [
  {
    path: BASE_PATH,
    name: "Users",
    component: () => import("@/views/users/pages/Users.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
