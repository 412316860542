export const DonationsModuleRoutes = [
  {
    path: "/donations",
    name: "Donations",
    component: () => import("@/views/reports_and_analytics/donations/pages/Donations.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  },
  {
    path: "/donation-logs",
    name: "DonationLogs",
    component: () => import("@/views/reports_and_analytics/donations/pages/DonationLogs.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
