export const AppActivitiesModuleRoutes = [
  {
    path: "/reports/app-activities",
    name: "AppActivities",
    component: () =>
      import(
        "@/views/reports_and_analytics/app_activities/pages/AppActivities.vue"
      ),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
