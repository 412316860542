const BASE_PATH = "/featured-videos";

export const FeaturedVideoModuleRoutes = [
  {
    path: BASE_PATH,
    name: "FeaturedVideos",
    component: () => import("@/views/featured_video/pages/FeaturedVideo.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
