import moment from "moment";

const getInitialState = () => {
  return {
    now: moment(),
    calendarType: "month",
    locationID: null
  };
};

export const state = getInitialState();

export const mutations = {
  SET_NOW: (state, payload) => {
    state.now = payload;
  },
  SET_CALENDAR_TYPE: (state, payload) => {
    state.calendarType = payload;
  },
  RESET_STATE: state => {
    Object.assign(state, getInitialState());
  },
  SET_LOCATION_ID: (state, locationID) => {
    state.locationID = locationID;
  }
};

export const actions = {};

export const getters = {
  now: state => state.now,
  calendarType: state => state.calendarType
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
