export const LoginModuleRoutes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/pages/Login.vue"),
    meta: {
      requiredAuth: false,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
