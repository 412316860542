import Vue from "vue";
import "./assets/app.scss";
import store from "./store";
import moment from "moment";
import App from "./App.vue";
import router from "./router";
import "./services/globalMixins";
import vuetify from "./plugins/vuetify";
import Components from "./services/components";
import InfiniteLoading from "vue-infinite-loading";
import Pusher from "pusher-js";
import Storage from "@/services/storage.js";
const storage = new Storage();

Object.keys(Components).forEach(name => {
  Vue.component(name, Components[name]);
});

Vue.prototype.moment = moment;

Vue.config.productionTip = false;
Vue.use(InfiniteLoading);

let token = storage.get("token");
Vue.prototype.$pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
  encrypted: false,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  authEndpoint: `${process.env.VUE_APP_API_URL}/api/pusher/auth`,
  auth: {
    headers: {
      Authorization: token ? `Bearer ${token}` : null
    }
  }
});

if (
  process.env.VUE_APP_MODE === "development" ||
  process.env.VUE_APP_MODE === "staging"
) {
  Pusher.log = function() {
    // console.log(msg);
  };
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
