import Vue from "vue";
import Vuex from "vuex";

import eventModule from "./modules/event.module";
import chatModule from "@/store/modules/chat.module";
import pusherModule from "@/store/modules/pusher.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    eventModule,
    chat: chatModule,
    pusher: pusherModule
  }
});
