const BASE_PATH = "/giving-videos";

export const GivingVideoModuleRoutes = [
  {
    path: BASE_PATH,
    name: "GivingVideos",
    component: () => import("@/views/giving_video/pages/GivingVideo.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
