import messagesRepository from "@/services/repositories/messagesRepository";

export const GET_DREAM_TEAM_CONVERSATIONS =
  "chat/action/dream-team-conversations";
export const GET_CONNECT_GROUP_CONVERSATIONS =
  "chat/action/connect-group-conversations";
export const GET_PASTOR_CONVERSATIONS = "chat/action/pastor-conversations";

export const SET_SELECTED_THREAD_ID = "chat/mutation/set-selected-thread-id";

export const APPEND_DREAM_TEAM_CONVERSATIONS =
  "chat/mutation/set-dream-team-chat-items";
export const SET_DREAM_TEAM_CONVERSATION_PAGINATION =
  "chat/mutation/set-chat-dream-team-conversation-pagination";
export const SET_DREAM_TEAM_CONVERSATION_LAST_MESSAGE =
  "chat/mutation/set-chat-dream-team-conversation-last-message";
export const SET_DREAM_TEAM_CONVERSATION_SELECTED_THREAD =
  "chat/mutation/set-chat-dream-team-conversation-selected-thread";
export const READ_DREAM_TEAM_CONVERSATION_MESSAGE =
  "chat/mutation/read-dream-team-conversation-message";

export const APPEND_CONNECT_GROUP_CONVERSATIONS =
  "chat/mutation/set-connect-group-chat-items";
export const SET_CONNECT_GROUP_CONVERSATION_PAGINATION =
  "chat/mutation/set-chat-connect-group-conversation-pagination";
export const SET_CONNECT_GROUP_CONVERSATION_LAST_MESSAGE =
  "chat/mutation/set-chat-connect-group-conversation-last-message";
export const SET_CONNECT_GROUP_CONVERSATION_SELECTED_THREAD =
  "chat/mutation/set-chat-connect-group-conversation-selected-thread";
export const READ_CONNECT_GROUP_CONVERSATION_MESSAGE =
  "chat/mutation/read-connect-group-conversation-message";

export const APPEND_PASTOR_CONVERSATIONS = "chat/mutation/set-pastor-items";
export const SET_PASTOR_CONVERSATION_PAGINATION =
  "chat/mutation/set-chat-pastor-conversation-pagination";
export const SET_PASTOR_CONVERSATION_LAST_MESSAGE =
  "chat/mutation/set-chat-pastor-conversation-last-message";
export const SET_PASTOR_CONVERSATION_SELECTED_THREAD =
  "chat/mutation/set-chat-pastor-conversation-selected-thread";
export const READ_PASTOR_CONVERSATION_MESSAGE =
  "chat/mutation/read-pastor-conversation-message";

export const RESET_SELECTED_CONTACT_CLASS =
  "chat/mutation/reset-selected-class";

const toContactObject = (type, contact) => {
  return {
    id: contact.id,
    name:
      contact.members.length > 0
        ? `${contact.members[0].first_name} ${contact.members[0].last_name}`
        : contact.name,
    lastMessage: contact.latest_message ? contact.latest_message.message : null,
    unreadCount: contact.unread_message_count
      ? contact.unread_message_count
      : 0,
    class: {
      card: "main-bg",
      name: "text-color-title",
      message: "text-color-black"
    },
    value: type
  };
};

const chatModule = {
  state: {
    selectedThreadId: null,

    dreamTeam: {
      items: [],
      pagination: {
        page: 1,
        perPage: 10,
        lastPage: 0,
        currentPage: 0
      }
    },

    connectGroup: {
      items: [],
      pagination: {
        page: 1,
        perPage: 10,
        lastPage: 0,
        currentPage: 0
      }
    },

    pastorChat: {
      items: [],
      pagination: {
        page: 1,
        perPage: 10,
        lastPage: 0,
        currentPage: 0
      }
    }
  },

  mutations: {
    [SET_SELECTED_THREAD_ID](state, threadID) {
      console.log(threadID);
      state.selectedThreadId = threadID;
    },

    [APPEND_DREAM_TEAM_CONVERSATIONS](state, items) {
      state.dreamTeam.items = [...state.dreamTeam.items, ...items];
    },
    [SET_DREAM_TEAM_CONVERSATION_PAGINATION](state, pagination) {
      state.dreamTeam.pagination = Object.assign({}, pagination);
    },
    [SET_DREAM_TEAM_CONVERSATION_LAST_MESSAGE](
      state,
      { conversation_id, message }
    ) {
      state.dreamTeam.items.map(conversation => {
        if (conversation.id === conversation_id) {
          conversation.lastMessage = message;
          if (state.selectedThreadId !== conversation_id) {
            conversation.unreadCount = conversation.unreadCount + 1;
          }
        }
        return conversation;
      });
      let threadIndex = state.dreamTeam.items.findIndex(
        item => item.id === conversation_id
      );
      state.dreamTeam.items.unshift(
        state.dreamTeam.items.splice(threadIndex, 1)[0]
      );
    },
    [SET_DREAM_TEAM_CONVERSATION_SELECTED_THREAD](state, conversation) {
      state.dreamTeam.items.map(item => {
        if (item.unreadCount === 0) {
          item.class.card = "main-bg";
        }

        return item;
      });

      conversation.unreadCount = 0;

      conversation.class = {
        card: "main-bg outline-thick",
        name: "text-color-title",
        message: "text-color-black"
      };
    },
    [READ_DREAM_TEAM_CONVERSATION_MESSAGE](state, conversationID) {
      state.dreamTeam.items.map(conversation => {
        if (conversation.id === conversationID) {
          conversation.unreadCount = 0;
        }
        return conversation;
      });
    },

    [APPEND_CONNECT_GROUP_CONVERSATIONS](state, items) {
      state.connectGroup.items = [...state.connectGroup.items, ...items];
    },
    [SET_CONNECT_GROUP_CONVERSATION_PAGINATION](state, pagination) {
      state.connectGroup.pagination = Object.assign({}, pagination);
    },
    [SET_CONNECT_GROUP_CONVERSATION_LAST_MESSAGE](
      state,
      { conversation_id, message }
    ) {
      state.connectGroup.items.map(conversation => {
        if (conversation.id === conversation_id) {
          conversation.lastMessage = message;
          if (state.selectedThreadId !== conversation_id) {
            conversation.unreadCount = conversation.unreadCount + 1;
          }
        }
        return conversation;
      });
      let threadIndex = state.connectGroup.items.findIndex(
        item => item.id === conversation_id
      );
      state.connectGroup.items.unshift(
        state.connectGroup.items.splice(threadIndex, 1)[0]
      );
    },
    [SET_CONNECT_GROUP_CONVERSATION_SELECTED_THREAD](state, conversation) {
      state.connectGroup.items.map(item => {
        if (item.unreadCount === 0) {
          item.class.card = "main-bg";
        }

        return item;
      });

      conversation.unreadCount = 0;

      conversation.class = {
        card: "main-bg outline-thick",
        name: "text-color-title",
        message: "text-color-black"
      };
    },
    [READ_CONNECT_GROUP_CONVERSATION_MESSAGE](state, conversationID) {
      state.connectGroup.items.map(conversation => {
        if (conversation.id === conversationID) {
          conversation.unreadCount = 0;
        }
        return conversation;
      });
    },

    [APPEND_PASTOR_CONVERSATIONS](state, items) {
      state.pastorChat.items = [...state.pastorChat.items, ...items];
    },
    [SET_PASTOR_CONVERSATION_PAGINATION](state, pagination) {
      state.pastorChat.pagination = Object.assign({}, pagination);
    },
    [SET_PASTOR_CONVERSATION_LAST_MESSAGE](
      state,
      { conversation_id, message }
    ) {
      state.pastorChat.items.map(conversation => {
        if (conversation.id === conversation_id) {
          conversation.lastMessage = message;
          if (state.selectedThreadId !== conversation_id) {
            conversation.unreadCount = conversation.unreadCount + 1;
          }
        }
        return conversation;
      });
      let threadIndex = state.pastorChat.items.findIndex(
        item => item.id === conversation_id
      );
      state.pastorChat.items.unshift(
        state.pastorChat.items.splice(threadIndex, 1)[0]
      );
    },
    [SET_PASTOR_CONVERSATION_SELECTED_THREAD](state, conversation) {
      state.pastorChat.items.map(item => {
        if (item.unreadCount === 0) {
          item.class.card = "main-bg";
        }

        return item;
      });

      conversation.unreadCount = 0;

      conversation.class = {
        card: "main-bg outline-thick",
        name: "text-color-title",
        message: "text-color-black"
      };
    },
    [READ_PASTOR_CONVERSATION_MESSAGE](state, conversationID) {
      state.pastorChat.items.map(conversation => {
        if (conversation.id === conversationID) {
          conversation.unreadCount = 0;
        }
        return conversation;
      });
    },

    [RESET_SELECTED_CONTACT_CLASS](state) {
      state.dreamTeam.items = state.dreamTeam.items.map(item => {
        item.class = Object.assign(
          {},
          {
            card: "main-bg",
            message: "text-color-black",
            name: "text-color-title"
          }
        );
        return item;
      });

      state.connectGroup.items = state.connectGroup.items.map(item => {
        item.class = Object.assign(
          {},
          {
            card: "main-bg",
            message: "text-color-black",
            name: "text-color-title"
          }
        );
        return item;
      });

      state.pastorChat.items = state.pastorChat.items.map(item => {
        item.class = Object.assign(
          {},
          {
            card: "main-bg",
            message: "text-color-black",
            name: "text-color-title"
          }
        );
        return item;
      });
    }
  },

  actions: {
    async [GET_DREAM_TEAM_CONVERSATIONS]({ commit, state }, locationID) {
      try {
        const typeChat = "dream-team";
        let params = {
          with_user_only: true,
          no_newly_made_chats: true,
          page: state.dreamTeam.pagination.page,
          per_page: state.dreamTeam.pagination.perPage
        };
        // location_id: locationID
        messagesRepository
          .getContactsViaRepository(typeChat, { params })
          .then(response => {
            let data = response.data.responseData;
            commit(SET_DREAM_TEAM_CONVERSATION_PAGINATION, {
              ...state.dreamTeam.pagination,
              lastPage: data.last_page
            });
            if (
              state.dreamTeam.pagination.currentPage <=
              state.dreamTeam.pagination.lastPage
            ) {
              commit(SET_DREAM_TEAM_CONVERSATION_PAGINATION, {
                ...state.dreamTeam.pagination,
                page: state.dreamTeam.pagination.page + 1
              });
            }
            const contacts = data.data.map(item =>
              toContactObject(typeChat, item)
            );
            commit(APPEND_DREAM_TEAM_CONVERSATIONS, contacts);
          });
      } catch (error) {
        console.log(error);
      }
    },

    async [GET_CONNECT_GROUP_CONVERSATIONS]({ commit, state }, locationID) {
      try {
        const typeChat = "connect-group";
        let params = {
          with_user_only: true,
          no_newly_made_chats: true,
          page: state.connectGroup.pagination.page,
          per_page: state.connectGroup.pagination.perPage
        };
        messagesRepository
          .getContactsViaRepository(typeChat, { params })
          .then(response => {
            let data = response.data.responseData;
            commit(SET_CONNECT_GROUP_CONVERSATION_PAGINATION, {
              ...state.connectGroup.pagination,
              lastPage: data.last_page
            });
            if (
              state.connectGroup.pagination.currentPage <=
              state.connectGroup.pagination.lastPage
            ) {
              commit(SET_CONNECT_GROUP_CONVERSATION_PAGINATION, {
                ...state.connectGroup.pagination,
                page: state.connectGroup.pagination.page + 1
              });
            }
            const contacts = data.data.map(item =>
              toContactObject(typeChat, item)
            );
            commit(APPEND_CONNECT_GROUP_CONVERSATIONS, contacts);
          });
      } catch (error) {
        console.log(error);
      }
    },

    async [GET_PASTOR_CONVERSATIONS]({ commit, state }, locationID) {
      try {
        const typeChat = "pastor-chat";
        let params = {
          with_user_only: true,
          no_newly_made_chats: true,
          page: state.pastorChat.pagination.page,
          per_page: state.pastorChat.pagination.perPage
        };
        messagesRepository
          .getContactsViaRepository(typeChat, { params })
          .then(response => {
            let data = response.data.responseData;
            commit(SET_PASTOR_CONVERSATION_PAGINATION, {
              ...state.pastorChat.pagination,
              lastPage: data.last_page
            });
            if (
              state.pastorChat.pagination.currentPage <=
              state.pastorChat.pagination.lastPage
            ) {
              commit(SET_PASTOR_CONVERSATION_PAGINATION, {
                ...state.pastorChat.pagination,
                page: state.pastorChat.pagination.page + 1
              });
            }
            const contacts = data.data.map(item =>
              toContactObject("pastor", item)
            );
            commit(APPEND_PASTOR_CONVERSATIONS, contacts);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }
};

export default chatModule;
