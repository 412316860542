const BASE_PATH = "/announcements";

export const AnnouncementModuleRoutes = [
  {
    path: BASE_PATH,
    name: "Announcements",
    component: () => import("@/views/announcement/pages/Announcement.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
