const BASE_PATH = "/worships";

export const WorshipModuleRoutes = [
  {
    path: BASE_PATH,
    name: "Worships",
    component: () => import("@/views/worship/pages/Worship.vue"),
    meta: {
      requiredAuth: true,
      roles: ["super_admin", "admin", "developer"]
    }
  }
];
